<template>
  <div id="app">
    <md-card>
      <md-card-header>
        <div class="md-title balance">Balance: J {{balance()}}</div>
      </md-card-header>
      <md-card-actions>
      </md-card-actions>
    </md-card>
    <md-content>
      <form novalidate class="md-layout">
        <Counter v-for="t in transactions" v-bind="t" v-bind:deltas="deltas" v-bind:key="keyids[t.id]"></Counter>
        <md-button type="submit" class="md-primary" :disabled="sending" @click="submitDeltas">
          Submit
        </md-button>
      </form>
    </md-content>
  </div>
</template>

<script>
import Counter from './components/Counter.vue'

export default {
  name: 'App',
  components: {
    Counter
  },
  data: function () {
    return {
      transactions: {},
      deltas: {},
      keyids: {}
    }
  },
  methods: {
    submitDeltas: function (event) {
      event.preventDefault()
      let urlpath = window.location.pathname.split("/");
      let uid = urlpath[urlpath.length-1];
      fetch(process.env.VUE_APP_API_URL+uid, {
        method: "POST",
        body: JSON.stringify(this.deltas)
      }).then((res) => res.json())
        .then((data) => {
          this.transactions = data;
          for (let t of this.transactions) {
            this.deltas[t.id] = 0;
            this.keyids[t.id] += 10;
          }
          this.balance();
        })
    },
    balance: function() {
      let bal = 0;
      for (let t of this.transactions) {
        if (t.bal) {
          bal += t.bal;
        }
      }
      return bal || 0
    }
  },
  mounted() {
    let urlpath = window.location.pathname.split("/");
    let uid = urlpath[urlpath.length-1];
    fetch(process.env.VUE_APP_API_URL+uid)
      .then((res) => res.json())
      .then((data) => {
        this.transactions = data;
        let keyid = 0;
        for (let t of this.transactions) {
          this.deltas[t.id] = 0;
          this.keyids[t.id] = keyid
          keyid++
        }
      })
  }
}
</script>

<style>
.md-content {
  padding: 1em 2em;
}
form.md-layout {
  flex-direction: column;
}
.md-card {
  width: calc(100% - 2em);
  margin: 1em;
  vertical-align: top;
  display: inline-block;
}
</style>
