<template>
<div class="row">
  <md-field>
    <label class="name">{{currCount}} {{ name }} (J {{ rate }}) <span v-if="baseline">Total Change: {{ count - ((count > baseline) ? baseline : 134) }}</span></label>
    <md-input type="number" v-model="delta" v-on:change="changedelta"></md-input>
  </md-field>
  <md-button class="md-raised" @click="decdelta">-</md-button>
  <md-button class="md-raised" @click="incdelta">+</md-button>
</div>
</template>

<script>
export default {
  name: 'Counter',
  props: {
    id: String,
    name: String,
    rate: Number,
    baseline: Number,
    count: Number,
    deltas: Object,
  },
  data: function() {
    return {
      currCount: 0,
      delta: this.deltas[this.id]
    }
  },
  methods: {
    incdelta: function() {
      this.delta = this.deltas[this.id] += 1;
    },
    decdelta: function() {
      this.delta = this.deltas[this.id] -= 1;
    },
    changedelta: function() {
      this.deltas[this.id] = Number(this.delta);
    }
  },
  mounted: function() {
    if (this.baseline) {
      this.currCount = this.baseline;
      this.deltas[this.id] = this.count;
      this.delta = this.deltas[this.id];
    } else {
      this.currCount = this.count;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .md-button {
    width: 1em;
    min-width: 35px;
    margin-top: 1em;
  }
  .md-input {
    width: 4em;
  }
  div.row {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  label.name {
    width: 25em;
  }
</style>
